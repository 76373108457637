import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import MsgService from "@/core/services/msg.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const RECOVER = "recover";

// mutation types
export const SET_AUTH = "setUser";
export const UPDATE_AUTH = "updateUser";
export const PURGE_AUTH = "logOut";

const prefix = "auth/";

const state = {
  user: {},
  exp_token: null,
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  expToken(state) {
    return state.exp_token;
  },
  currentUser(state) {
    return state.user ? state.user : {};
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, payload) {
    return ApiService.post(prefix + "login", payload).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return Promise.resolve(data.response);
    });
  },
  [LOGOUT](context) {
    return ApiService.get(prefix + "logout").then(() => {
      context.commit(PURGE_AUTH);
      return Promise.resolve();
    });
  },
  [REGISTER](context, payload) {
    return ApiService.post(prefix + "register", payload).then(({ data }) => {
      if (data.message) {
        MsgService.show(data.message);
      }
      return Promise.resolve(data);
    });
  },
  [RECOVER](context, payload) {
    return ApiService.post(prefix + "recover", payload).then(({ data }) => {
      if (data.message) {
        MsgService.show(data.message);
      }
      return Promise.resolve(data);
    });
  },
  [VERIFY_AUTH](context) {
    return new Promise((resolve, reject) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(prefix + "verify")
          .then(({ data }) => {
            context.commit(SET_AUTH, data);
            resolve();
          })
          .catch(error => reject(error));
      } else {
        context.commit(PURGE_AUTH);
        reject({ response: { status: 401 } });
      }
    });
  }
};

const mutations = {
  [SET_AUTH](state, payload) {
    state.isAuthenticated = true;
    state.user = payload.response;
    state.exp_token = payload.exp_token;
    JwtService.saveToken(payload.token, payload.exp_token);
    ApiService.setHeader();
  },
  [UPDATE_AUTH](state, payload) {
    JwtService.saveToken(payload.token, payload.exp_token);
    state.exp_token = payload.exp_token;
    ApiService.setHeader();
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    JwtService.destroyToken();
    ApiService.removeHeader();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
