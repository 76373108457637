// mutation types
export const APPEND_ITEM = "appendItem";
export const APPEND_DADOSGERAIS = "appendDadosGerais";
export const APPEND_ONEPAGE = "appendOnepage";
export const ALTER_ONEPAGE = "alterOnepage";
export const APPEND_PLANOVENDAS = "appendPlanoVendas";
export const APPEND_PRECOVENDAS = "appendPrecoVendas";
export const APPEND_AUTOCONSUMO = "appendAutoconsumo";
export const APPEND_CUSTODESPESA = "appendCustoDespesa";
export const ALTER_CUSTODESPESA = "alterCustoDespesa";
export const APPEND_INDICADORES = "appendIndicadores";

// action types
export const ADD_ITEM = "addItem";
export const ADD_DADOSGERAIS = "addDadosGerais";
export const ADD_ONEPAGE = "addOnepage";
export const EDIT_ONEPAGE = "editOnepage";
export const ADD_PLANOVENDAS = "addPlanoVendas";
export const ADD_PRECOVENDAS = "addPrecoVendas";
export const ADD_AUTOCONSUMO = "addAutoconsumo";
export const ADD_CUSTODESPESA = "addCustoDespesa";
export const EDIT_CUSTODESPESA = "editCustoDespesa";
export const ADD_INDICADORES = "addIndicadores";

export default {
  state: {
    itens: [
      {
        id: 0,
        titulo: "Associação de Moradores e Pequenos Produtores Rurais",
        objeto_proposto:
          "Aquisição de matrizes e reprodutores de ovinos/caprinos, construção de centro de manejo, implantação de 8,5 há de leucena, 2 há de sorgo, construção de 3,8 km de cerca, aquisição de conjunto forrageiro e instalação de sistema de criação de galinha caipira e ATS, beneficiando 17 famílias naquela Comunidade.",
        sigla_tipo: "PP",
        tipo: "Projeto Produtivo",
        data: "17/02/2021",
        atividades: [
          {
            nome: "Caprinovinocultura",
            descricao:
              "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
            checked: true
          },
          {
            nome: "Apicultura",
            descricao:
              "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio, neque?",
            checked: false
          },
          {
            nome: "Avicultura",
            descricao: "Lorem ipsum dolor sit amet consectetur adipisicing.",
            checked: true
          },
          {
            nome: "Piscicultura",
            descricao:
              "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
            checked: false
          },
          {
            nome: "Artenasato",
            descricao:
              "Lorem ipsum, dolor sit amet consectetur adipisicing elit.\n              Eaad eligendi corporis.",
            checked: false
          }
        ],
        status: {
          text: "Cadastrando",
          class: "warning"
        },
        dados_gerais: {
          idprojeto: 0,
          objeto_proposto:
            "Aquisição de matrizes e reprodutores de ovinos/caprinos, construção de centro de manejo, implantação de 8,5 há de leucena, 2 há de sorgo, construção de 3,8 km de cerca, aquisição de conjunto forrageiro e instalação de sistema de criação de galinha caipira e ATS, beneficiando 17 famílias naquela Comunidade.",
          implementacao: "1",
          analise_economica: "5",
          impostos: "0"
        },
        investimentos: [
          {
            id: 0,
            path: "investimentos",
            idprojeto: 0,
            atividade_relacionada: "Coletiva",
            atividade: "Coletiva",
            especificacao: "Aquisição de conjunto forrageiro",
            tipo: "Equip. e material permanente - Investimento",
            unidade_medida: "Unidade",
            quantidade: "1",
            preco_unitario: "4800",
            situacao: "A realizar",
            fonte_financiamento: "2"
          },
          {
            id: 1,
            path: "investimentos",
            idprojeto: 0,
            atividade_relacionada: "Coletiva",
            atividade: "Coletiva",
            especificacao:
              "Aquisição de matrizes caprina ¾ de sangue Anglo-Nubiana",
            tipo: "Semoventes - Investimento",
            unidade_medida: "Cabeça",
            quantidade: "80",
            preco_unitario: "300",
            situacao: "A realizar",
            fonte_financiamento: "2"
          },
          {
            id: 2,
            path: "investimentos",
            idprojeto: 0,
            atividade_relacionada: "Coletiva",
            atividade: "Coletiva",
            especificacao:
              "Aquisição de matrizes ovinas ¾ de sangue Santa Inês",
            tipo: "Semoventes - Investimento",
            unidade_medida: "Cabeça",
            quantidade: "40",
            preco_unitario: "300",
            situacao: "A realizar",
            fonte_financiamento: "2"
          },
          {
            id: 3,
            path: "investimentos",
            idprojeto: 0,
            atividade_relacionada: "Coletiva",
            atividade: "Coletiva",
            especificacao:
              "Aquisição de reprodutores caprino PO ou PC Anglo-Nubiano",
            tipo: "Semoventes - Investimento",
            unidade_medida: "Cabeça",
            quantidade: "4",
            preco_unitario: "1000",
            situacao: "A realizar",
            fonte_financiamento: "2"
          },
          {
            id: 4,
            path: "investimentos",
            idprojeto: 0,
            atividade_relacionada: "Coletiva",
            atividade: "Coletiva",
            especificacao:
              "Aquisição de reprodutores ovinos PO ou PC Santa Inês",
            tipo: "Semoventes - Investimento",
            unidade_medida: "Cabeça",
            quantidade: "2",
            preco_unitario: "1000",
            situacao: "A realizar",
            fonte_financiamento: "2"
          },
          {
            path: "investimentos",
            idprojeto: 0,
            atividade_relacionada: "Coletiva",
            atividade: "Coletiva",
            especificacao: "Banco de proteína Leucena",
            tipo: "Material de consumo - Custeio",
            unidade_medida: "Hectare (Ha)",
            quantidade: "8.5",
            preco_unitario: "718.50",
            situacao: "A realizar",
            fonte_financiamento: "2",
            id: 5
          },
          {
            id: 6,
            path: "investimentos",
            idprojeto: 0,
            atividade_relacionada: "Coletiva",
            atividade: "Coletiva",
            especificacao: "Construção de centro de manejo (APRISCO)",
            tipo: "Obras e instalações - Investimento",
            unidade_medida: "Unidade",
            quantidade: "12",
            preco_unitario: "2104.86",
            situacao: "A realizar",
            fonte_financiamento: "2"
          },
          {
            id: 7,
            path: "investimentos",
            idprojeto: 0,
            atividade_relacionada: "Coletiva",
            atividade: "Coletiva",
            especificacao: "Construção de cerca com 7 fios",
            tipo: "Terrenos - Investimento",
            unidade_medida: "Metro (m)",
            quantidade: "3800",
            preco_unitario: "6.12",
            situacao: "A realizar",
            fonte_financiamento: "2"
          },
          {
            id: 8,
            path: "investimentos",
            idprojeto: 0,
            atividade_relacionada: "Coletiva",
            atividade: "Coletiva",
            especificacao:
              "Instalação de sistema alternativo de criação de galinha caipira",
            tipo: "Obras e instalações - Investimento",
            unidade_medida: "Unidade",
            quantidade: "5",
            preco_unitario: "3524.08",
            situacao: "A realizar",
            fonte_financiamento: "2"
          }
        ],
        depreciacao: [
          {
            id: 0,
            path: "depreciacao",
            idprojeto: 0,
            tipo: "Equip. e material permanente - Investimento",
            percentual: "5"
          },
          {
            id: 1,
            path: "depreciacao",
            idprojeto: 0,
            tipo: "Obras e instalações - Investimento",
            percentual: "10"
          }
        ],
        produtos: [
          {
            path: "produtos",
            idprojeto: 0,
            descricao: "Matrizes descartes",
            atividade: "Caprinovinocultura",
            unidade_medida: "Cabeça",
            id: 0,
            plano_vendas: {
              ano1: {
                mes_1: "0",
                mes_2: "0",
                mes_3: "0",
                mes_4: "0",
                mes_5: "0",
                mes_6: "0",
                mes_7: "2",
                mes_8: "2",
                mes_9: "2",
                mes_10: "2",
                mes_11: "2",
                mes_12: "2"
              },
              ano2: {
                mes_1: "10",
                mes_2: "10",
                mes_3: "10",
                mes_4: "10",
                mes_5: "10",
                mes_6: "10",
                mes_7: "14",
                mes_8: "15",
                mes_9: "15",
                mes_10: "15",
                mes_11: "15",
                mes_12: "15"
              }
            },
            preco_vendas: {
              ano1: { preco: "300" },
              ano2: { preco: "300" }
            },
            autoconsumo: {
              ano1: { percentual: "1" },
              ano2: { percentual: "1" }
            }
          },
          {
            id: 1,
            path: "produtos",
            idprojeto: 0,
            descricao: "Machos de 1 a 2 anos",
            atividade: "Caprinovinocultura",
            unidade_medida: "Cabeça",
            plano_vendas: {
              ano1: {
                mes_1: "0",
                mes_2: "1",
                mes_3: "0",
                mes_4: "1",
                mes_5: "0",
                mes_6: "1",
                mes_7: "0",
                mes_8: "1",
                mes_9: "0",
                mes_10: "1",
                mes_11: "0",
                mes_12: "1"
              },
              ano2: {
                mes_1: "0",
                mes_2: "0",
                mes_3: "0",
                mes_4: "0",
                mes_5: "0",
                mes_6: "0",
                mes_7: "0",
                mes_8: "0",
                mes_9: "0",
                mes_10: "0",
                mes_11: "0",
                mes_12: "0"
              }
            },
            preco_vendas: {
              ano1: { preco: "250" },
              ano2: { preco: "250" }
            },
            autoconsumo: {
              ano1: { percentual: "1" },
              ano2: { percentual: "0" }
            }
          },
          {
            id: 2,
            path: "produtos",
            idprojeto: 0,
            descricao: "Marchos de 0 a 1 ano",
            atividade: "Caprinovinocultura",
            unidade_medida: "Cabeça",
            plano_vendas: {
              ano1: {
                mes_1: "15",
                mes_2: "15",
                mes_3: "15",
                mes_4: "15",
                mes_5: "15",
                mes_6: "15",
                mes_7: "15",
                mes_8: "15",
                mes_9: "15",
                mes_10: "16",
                mes_11: "16",
                mes_12: "16"
              },
              ano2: {
                mes_1: "23",
                mes_2: "23",
                mes_3: "23",
                mes_4: "23",
                mes_5: "23",
                mes_6: "23",
                mes_7: "23",
                mes_8: "23",
                mes_9: "23",
                mes_10: "23",
                mes_11: "24",
                mes_12: "24"
              }
            },
            preco_vendas: {
              ano1: { preco: "200" },
              ano2: { preco: "200" }
            },
            autoconsumo: {
              ano1: { percentual: "1" },
              ano2: { percentual: "1" }
            }
          },
          {
            id: 3,
            path: "produtos",
            idprojeto: 0,
            descricao: "Aves",
            atividade: "Avicultura",
            unidade_medida: "Cabeça",
            plano_vendas: {
              ano1: {
                mes_1: "200",
                mes_2: "200",
                mes_3: "200",
                mes_4: "200",
                mes_5: "200",
                mes_6: "200",
                mes_7: "200",
                mes_8: "220",
                mes_9: "220",
                mes_10: "220",
                mes_11: "220",
                mes_12: "220"
              },
              ano2: {
                mes_1: "200",
                mes_2: "200",
                mes_3: "200",
                mes_4: "200",
                mes_5: "200",
                mes_6: "200",
                mes_7: "200",
                mes_8: "220",
                mes_9: "220",
                mes_10: "220",
                mes_11: "220",
                mes_12: "220"
              }
            },
            preco_vendas: {
              ano1: { preco: "30" },
              ano2: { preco: "30" }
            },
            autoconsumo: {
              ano1: { percentual: "1" },
              ano2: { percentual: "1" }
            }
          }
        ],
        indicadores: {
          idprojeto: 0,
          tma: "6",
          otimista_receita: "5",
          otimista_custo: "10",
          pessimista_receita: "10",
          pessimista_custo: "10"
        },
        custo_despesa: [
          {
            id: 0,
            tipo: "Outras Despesas Operacionais",
            descricao: "Material de limpeza",
            atividade: "Avicultura",
            valor_total: {
              ano1: "5777.46",
              ano2: "5777.46"
            }
          },
          {
            id: 1,
            tipo: "Outras Despesas Operacionais",
            descricao: "Material de limpeza",
            atividade: "Caprinovinocultura",
            valor_total: {
              ano1: "5777.46",
              ano2: "5777.46"
            }
          }
        ]
      }
    ]
  },
  getters: {
    itens(state) {
      return state.itens;
    },
    lastItensId(state) {
      return state.itens.length ? state.itens[state.itens.length - 1].id : null;
    },
    getItemById: state => id => {
      return state.itens.find(item => item.id === id);
    },
    produtos: (state, getters) => id => {
      const projeto = getters.getItemById(id);

      return projeto ? projeto.produtos : null;
    },
    lastProdutoId: (state, getters) => id => {
      const produtos = getters.produtos(id);

      return produtos && produtos.length
        ? produtos[produtos.length - 1].id
        : null;
    },
    getProdutoById: (state, getters) => (idprojeto, idproduto) => {
      const produtos = getters.produtos(idprojeto).length
        ? state.itens.find(item => item.id === idprojeto)
        : null;
      return produtos ? produtos[idproduto] : null;
    },
    itemOnepage: (state, getters) => (idprojeto, path) => {
      const projeto = getters.getItemById(idprojeto);

      return projeto ? projeto[path] : [];
    },
    lastItemOnepageId: (state, getters) => (idprojeto, path) => {
      const itemOnepage = getters.itemOnepage(idprojeto, path);

      return itemOnepage && itemOnepage.length
        ? itemOnepage[itemOnepage.length - 1].id
        : null;
    },
    getItemOnepageById: (state, getters) => (
      idprojeto,
      path,
      idItemOnepage
    ) => {
      const itemOnepage = getters.itemOnepage(idprojeto, path);

      return itemOnepage ? itemOnepage[idItemOnepage] : null;
    }
  },
  actions: {
    /**
     * Add item
     * @param state
     * @param payload
     */
    async [ADD_ITEM](context, payload) {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = dd + "/" + mm + "/" + yyyy;

      const lastItensId = context.getters.lastItensId;

      let item = {
        id: lastItensId ? lastItensId + 1 : 0,
        ...payload,
        data: today,
        status: {
          text: "Cadastrando",
          class: "warning"
        }
      };

      await context.commit(APPEND_ITEM, item);
    },
    /**
     * Add dados gerais
     * @param state
     * @param payload
     */
    async [ADD_DADOSGERAIS](context, payload) {
      await context.commit(APPEND_DADOSGERAIS, payload);
    },
    /**
     * Add 'payload.path'
     * @param state
     * @param payload
     */
    async [ADD_ONEPAGE](context, payload) {
      const lastItemOnepageId = context.getters.lastItemOnepageId(
        payload.idprojeto,
        payload.path
      );

      let item = {
        id: lastItemOnepageId != null ? lastItemOnepageId + 1 : 0,
        ...payload
      };

      await context.commit(APPEND_ONEPAGE, item);
    },
    /**
     * Edit 'payload.path'['payload.id']
     * @param state
     * @param payload
     */
    async [EDIT_ONEPAGE](context, payload) {
      await context.commit(ALTER_ONEPAGE, payload);
    },
    /**
     * Add produtos[payload.idproduto].plano_vendas
     * @param state
     * @param payload
     */
    async [ADD_PLANOVENDAS](context, payload) {
      await context.commit(APPEND_PLANOVENDAS, payload);
    },
    /**
     * Add produtos[payload.idproduto].preco_vendas
     * @param state
     * @param payload
     */
    async [ADD_PRECOVENDAS](context, payload) {
      await context.commit(APPEND_PRECOVENDAS, payload);
    },
    /**
     * Add produtos[payload.idproduto].autoconsumo
     * @param state
     * @param payload
     */
    async [ADD_AUTOCONSUMO](context, payload) {
      await context.commit(APPEND_AUTOCONSUMO, payload);
    },
    /**
     * Add 'payload.path'
     * @param state
     * @param payload
     */
    async [ADD_CUSTODESPESA](context, payload) {
      const lastItemOnepageId = context.getters.lastItemOnepageId(
        payload.idprojeto,
        "custo_despesa"
      );

      let item = {
        id: lastItemOnepageId != null ? lastItemOnepageId + 1 : 0,
        ...payload
      };

      await context.commit(APPEND_CUSTODESPESA, item);
    },
    /**
     * Edit 'payload.path'['payload.id']
     * @param state
     * @param payload
     */
    async [EDIT_CUSTODESPESA](context, payload) {
      await context.commit(ALTER_CUSTODESPESA, payload);
    },
    /**
     * Add indicadores
     * @param state
     * @param payload
     */
    async [ADD_INDICADORES](context, payload) {
      await context.commit(APPEND_INDICADORES, payload);
    }
  },
  mutations: {
    [APPEND_ITEM](state, payload) {
      state.itens.push(payload);
    },
    [APPEND_DADOSGERAIS](state, payload) {
      state.itens[payload.idprojeto].dados_gerais = payload;
    },
    [APPEND_ONEPAGE](state, payload) {
      if (!state.itens[payload.idprojeto][payload.path]) {
        state.itens[payload.idprojeto][payload.path] = [];
      }
      state.itens[payload.idprojeto][payload.path].push(payload);
    },
    [ALTER_ONEPAGE](state, payload) {
      state.itens[payload.idprojeto][payload.path][payload.id] = payload;
    },
    [APPEND_PLANOVENDAS](state, payload) {
      if (
        !state.itens[payload.idprojeto].produtos[payload.idproduto].plano_vendas
      ) {
        state.itens[payload.idprojeto].produtos[
          payload.idproduto
        ].plano_vendas = {};
      }
      state.itens[payload.idprojeto].produtos[payload.idproduto].plano_vendas[
        payload.ano
      ] = {
        mes_1: payload.mes_1,
        mes_2: payload.mes_2,
        mes_3: payload.mes_3,
        mes_4: payload.mes_4,
        mes_5: payload.mes_5,
        mes_6: payload.mes_6,
        mes_7: payload.mes_7,
        mes_8: payload.mes_8,
        mes_9: payload.mes_9,
        mes_10: payload.mes_10,
        mes_11: payload.mes_11,
        mes_12: payload.mes_12
      };
    },
    [APPEND_PRECOVENDAS](state, payload) {
      if (
        !state.itens[payload.idprojeto].produtos[payload.idproduto].preco_vendas
      ) {
        state.itens[payload.idprojeto].produtos[
          payload.idproduto
        ].preco_vendas = {};
      }
      state.itens[payload.idprojeto].produtos[payload.idproduto].preco_vendas[
        payload.ano
      ] = {
        preco: payload.preco
      };
    },
    [APPEND_AUTOCONSUMO](state, payload) {
      if (
        !state.itens[payload.idprojeto].produtos[payload.idproduto].autoconsumo
      ) {
        state.itens[payload.idprojeto].produtos[
          payload.idproduto
        ].autoconsumo = {};
      }
      state.itens[payload.idprojeto].produtos[payload.idproduto].autoconsumo[
        payload.ano
      ] = {
        percentual: payload.percentual
      };
    },
    [APPEND_CUSTODESPESA](state, payload) {
      if (!state.itens[payload.idprojeto].custo_despesa) {
        state.itens[payload.idprojeto].custo_despesa = [];
      }

      const item = {
        id: payload.id,
        tipo: payload.despesa.tipo,
        descricao: payload.despesa.descricao,
        atividade: payload.atividade,
        valor_total: {
          ano1: "",
          ano2: ""
        }
      };
      item.valor_total[payload.ano] = payload.valor_total;

      state.itens[payload.idprojeto].custo_despesa.push(item);
    },
    [ALTER_CUSTODESPESA](state, payload) {
      const old = {
        ...state.itens[payload.idprojeto].custo_despesa[payload.id]
      };
      const item = {
        id: payload.id,
        tipo: payload.despesa.tipo,
        descricao: payload.despesa.descricao,
        atividade: payload.atividade,
        valor_total: old.valor_total
      };
      item.valor_total[payload.ano] = payload.valor_total;

      state.itens[payload.idprojeto].custo_despesa[payload.id] = item;
    },
    [APPEND_INDICADORES](state, payload) {
      state.itens[payload.idprojeto].indicadores = payload;
    }
  }
};
