import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routes = [
  {
    path: "/app",
    redirect: { name: "inicio" },
    component: () => import("@/view/layout/Layout"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "dashboard",
        name: "inicio",
        component: () => import("@/view/pages/Dashboard")
      },
      {
        path: "profile",
        name: "profile",
        component: () => import("@/view/pages/profile/ProfileForm")
      },
      {
        path: "profile_photo",
        name: "profile_photo",
        component: () => import("@/view/pages/profile/ProfilePhotoForm")
      },
      {
        path: "profile_password",
        name: "profile_password",
        component: () => import("@/view/pages/profile/ProfilePasswordForm")
      },
      {
        path: "pip",
        component: {
          render: c => c("router-view"),
        },
        redirect: { name: "inicio" },
        children: [
          {
            path: "new/:tipo",
            name: "pip_new",
            component: () => import("@/view/pages/pip/PipForm"),
            props: true
          },
          {
            path: ":idplano_investimento",
            component: {
              render: c => c("router-view"),
            },
            children: [
              {
                path: "",
                name: "pip_ver",
                component: () => import("@/view/pages/pip/PipOnepage.vue"),
                props: true
              },
              {
                path: "dados_gerais",
                name: "pip_dados_gerais_form",
                component: () =>
                  import("@/view/pages/pip/dados_gerais/PipDadosGeraisForm.vue"),
                props: true
              },
              {
                path: "potencial_risco",
                name: "pip_potencial_risco_form",
                component: () =>
                  import("@/view/pages/pip/potencial_risco/PipPotencialRiscoForm.vue"),
                props: true
              },
              {
                path: "produto",
                component: {
                  render: c => c("router-view"),
                },
                children: [
                  {
                    path: "",
                    name: "pip_produto_list",
                    component: () =>
                      import("@/view/pages/pip/produto/PipProdutoList.vue"),
                    props: true,
                  },
                  {
                    path: "new",
                    name: "pip_produto_new",
                    component: () =>
                      import("@/view/pages/pip/produto/PipProdutoForm.vue"),
                    props: true
                  },
                  {
                    path: ":idpip_produto",
                    name: "pip_produto_edit",
                    component: () =>
                      import("@/view/pages/pip/produto/PipProdutoForm.vue"),
                    props: true
                  }
                ]
              },
              {
                path: "insumo",
                component: {
                  render: c => c("router-view"),
                },
                children: [
                  {
                    path: "",
                    name: "pip_insumo_list",
                    component: () =>
                      import("@/view/pages/pip/insumo/PipInsumoList.vue"),
                    props: true,
                  },
                  {
                    path: "new",
                    name: "pip_insumo_new",
                    component: () =>
                      import("@/view/pages/pip/insumo/PipInsumoForm.vue"),
                    props: true
                  },
                  {
                    path: ":idpip_produto_insumo",
                    name: "pip_insumo_edit",
                    component: () =>
                      import("@/view/pages/pip/insumo/PipInsumoForm.vue"),
                    props: true
                  }
                ]
              },
              {
                path: "investimento",
                component: {
                  render: c => c("router-view"),
                },
                children: [
                  {
                    path: "",
                    name: "pip_investimento_list",
                    component: () =>
                      import("@/view/pages/pip/investimento/PipInvestimentoList.vue"),
                    props: true,
                  },
                  {
                    path: "new",
                    name: "pip_investimento_new",
                    component: () =>
                      import("@/view/pages/pip/investimento/PipInvestimentoForm.vue"),
                    props: true
                  },
                  {
                    path: ":idpip_investimento",
                    name: "pip_investimento_edit",
                    component: () =>
                      import("@/view/pages/pip/investimento/PipInvestimentoForm.vue"),
                    props: true
                  }
                ]
              },
              {
                path: "depreciacao",
                component: {
                  render: c => c("router-view"),
                },
                children: [
                  {
                    path: "",
                    name: "pip_depreciacao_list",
                    component: () =>
                      import("@/view/pages/pip/depreciacao/PipDepreciacaoList.vue"),
                    props: true,
                  },
                  {
                    path: "new",
                    name: "pip_depreciacao_new",
                    component: () =>
                      import("@/view/pages/pip/depreciacao/PipDepreciacaoForm.vue"),
                    props: true
                  },
                  {
                    path: ":idpip_investimento_depreciacao",
                    name: "pip_depreciacao_edit",
                    component: () =>
                      import("@/view/pages/pip/depreciacao/PipDepreciacaoForm.vue"),
                    props: true
                  }
                ]
              },
              {
                path: "resumo_investimento",
                name: "pip_resumo_investimento_list",
                component: () =>
                  import("@/view/pages/pip/resumo_investimento/PipResumoInvestimentoList.vue"),
                props: true
              },
              {
                path: "plano_vendas",
                component: {
                  render: c => c("router-view"),
                },
                children: [
                  {
                    path: "",
                    name: "pip_plano_vendas_list",
                    component: () =>
                      import("@/view/pages/pip/plano_vendas/PipPlanoVendasList.vue"),
                    props: true,
                  },
                  {
                    path: ":idpip_produto",
                    name: "pip_plano_vendas_edit",
                    component: () =>
                      import("@/view/pages/pip/plano_vendas/PipPlanoVendasForm.vue"),
                    props: true
                  }
                ]
              },
              {
                path: "preco_vendas",
                component: {
                  render: c => c("router-view"),
                },
                children: [
                  {
                    path: "",
                    name: "pip_preco_vendas_list",
                    component: () =>
                      import("@/view/pages/pip/preco_vendas/PipPrecoVendasList.vue"),
                    props: true,
                  },
                  {
                    path: ":idpip_produto",
                    name: "pip_preco_vendas_edit",
                    component: () =>
                      import("@/view/pages/pip/preco_vendas/PipPrecoVendasForm.vue"),
                    props: true
                  }
                ]
              },
              {
                path: "plano_producao",
                component: {
                  render: c => c("router-view"),
                },
                children: [
                  {
                    path: "",
                    name: "pip_plano_producao_list",
                    component: () =>
                      import("@/view/pages/pip/plano_producao/PipPlanoProducaoList.vue"),
                    props: true,
                  },
                  {
                    path: ":idpip_produto",
                    name: "pip_plano_producao_edit",
                    component: () =>
                      import("@/view/pages/pip/plano_producao/PipPlanoProducaoForm.vue"),
                    props: true
                  }
                ]
              },
              {
                path: "mao_de_obra",
                component: {
                  render: c => c("router-view"),
                },
                children: [
                  {
                    path: "",
                    name: "pip_mao_de_obra_list",
                    component: () =>
                      import("@/view/pages/pip/mao_de_obra/PipMaoDeObraList.vue"),
                    props: true,
                  },
                  {
                    path: "new",
                    name: "pip_mao_de_obra_new",
                    component: () =>
                      import("@/view/pages/pip/mao_de_obra/PipMaoDeObraForm.vue"),
                    props: true
                  },
                  {
                    path: ":idpip_mao_de_obra",
                    name: "pip_mao_de_obra_edit",
                    component: () =>
                      import("@/view/pages/pip/mao_de_obra/PipMaoDeObraForm.vue"),
                    props: true
                  }
                ]
              },
              {
                path: "encargo",
                component: {
                  render: c => c("router-view"),
                },
                children: [
                  {
                    path: "",
                    name: "pip_encargo_list",
                    component: () =>
                      import("@/view/pages/pip/encargo/PipEncargoList.vue"),
                    props: true,
                  },
                  {
                    path: "new",
                    name: "pip_encargo_new",
                    component: () =>
                      import("@/view/pages/pip/encargo/PipEncargoForm.vue"),
                    props: true
                  },
                  {
                    path: ":idpip_mao_de_obra_encargo",
                    name: "pip_encargo_edit",
                    component: () =>
                      import("@/view/pages/pip/encargo/PipEncargoForm.vue"),
                    props: true
                  }
                ]
              },
              {
                path: "resumo_mao_de_obra",
                name: "pip_resumo_mao_de_obra_list",
                component: () =>
                  import("@/view/pages/pip/resumo_mao_de_obra/PipResumoMaoDeObraList.vue"),
                props: true
              },
              {
                path: "receita",
                component: {
                  render: c => c("router-view"),
                },
                children: [
                  {
                    path: "",
                    name: "pip_receita_list",
                    component: () =>
                      import("@/view/pages/pip/receita/PipReceitaList.vue"),
                    props: true,
                  },
                  {
                    path: ":idpip_produto",
                    name: "pip_receita_edit",
                    component: () =>
                      import("@/view/pages/pip/receita/PipReceitaForm.vue"),
                    props: true
                  }
                ]
              },
              {
                path: "autoconsumo",
                component: {
                  render: c => c("router-view"),
                },
                children: [
                  {
                    path: "",
                    name: "pip_autoconsumo_list",
                    component: () =>
                      import("@/view/pages/pip/autoconsumo/PipAutoconsumoList.vue"),
                    props: true,
                  },
                  {
                    path: ":idpip_produto",
                    name: "pip_autoconsumo_edit",
                    component: () =>
                      import("@/view/pages/pip/autoconsumo/PipAutoconsumoForm.vue"),
                    props: true
                  }
                ]
              },
              {
                path: "despesa_operacional",
                component: {
                  render: c => c("router-view"),
                },
                children: [
                  {
                    path: "",
                    name: "pip_despesa_operacional_list",
                    component: () =>
                      import("@/view/pages/pip/despesa_operacional/PipDespesaOperacionalList.vue"),
                    props: true,
                  },
                  {
                    path: "new",
                    name: "pip_despesa_operacional_new",
                    component: () =>
                      import("@/view/pages/pip/despesa_operacional/PipDespesaOperacionalForm.vue"),
                    props: true
                  },
                  {
                    path: ":idpip_despesa_operacional",
                    name: "pip_despesa_operacional_edit",
                    component: () =>
                      import("@/view/pages/pip/despesa_operacional/PipDespesaOperacionalForm.vue"),
                    props: true
                  }
                ]
              },
              {
                path: "resumo_despesa_operacional",
                name: "pip_resumo_despesa_operacional_list",
                component: () =>
                  import("@/view/pages/pip/resumo_despesa_operacional/PipResumoDespesaOperacionalList.vue"),
                props: true
              },
              {
                path: "custo_materiais",
                component: {
                  render: c => c("router-view"),
                },
                children: [
                  {
                    path: "",
                    name: "pip_custo_materiais_list",
                    component: () =>
                      import("@/view/pages/pip/custo_materiais/PipCustoMateriaisList.vue"),
                    props: true,
                  },
                  {
                    path: "new",
                    name: "pip_custo_materiais_new",
                    component: () =>
                      import("@/view/pages/pip/custo_materiais/PipCustoMateriaisForm.vue"),
                    props: true
                  }
                ]
              },
              {
                path: "custo_producao",
                component: {
                  render: c => c("router-view"),
                },
                children: [
                  {
                    path: "",
                    name: "pip_custo_producao_list",
                    component: () =>
                      import("@/view/pages/pip/custo_producao/PipCustoProducaoList.vue"),
                    props: true,
                  },
                  {
                    path: "new",
                    name: "pip_custo_producao_new",
                    component: () =>
                      import("@/view/pages/pip/custo_producao/PipCustoProducaoForm.vue"),
                    props: true
                  },
                  {
                    path: ":idpip_custo_producao",
                    name: "pip_custo_producao_edit",
                    component: () =>
                      import("@/view/pages/pip/custo_producao/PipCustoProducaoForm.vue"),
                    props: true
                  }
                ]
              },
              {
                path: "resumo_custo_producao",
                name: "pip_resumo_custo_producao_list",
                component: () =>
                  import("@/view/pages/pip/resumo_custo_producao/PipResumoCustoProducaoList.vue"),
                props: true
              },
              {
                path: "dre",
                component: {
                  render: c => c("router-view"),
                },
                children: [
                  {
                    path: "",
                    name: "pip_dre_list",
                    component: () =>
                      import("@/view/pages/pip/dre/PipDreList.vue"),
                    props: true,
                  },
                  {
                    path: "detail/:ano",
                    name: "pip_dre_detail_list",
                    component: () =>
                      import("@/view/pages/pip/dre/PipDreDetailList.vue"),
                    props: true,
                  },
                  {
                    path: "new",
                    name: "pip_dre_new",
                    component: () =>
                      import("@/view/pages/pip/dre/PipDreForm.vue"),
                    props: true
                  },
                  {
                    path: ":iddeducao",
                    name: "pip_dre_edit",
                    component: () =>
                      import("@/view/pages/pip/dre/PipDreForm.vue"),
                    props: true
                  }
                ]
              },
              {
                path: "ponto_equilibrio",
                name: "pip_ponto_equilibrio_list",
                component: () =>
                  import("@/view/pages/pip/ponto_equilibrio/PipPontoEquilibrioList.vue"),
                props: true
              },
              {
                path: "capital_giro",
                component: {
                  render: c => c("router-view"),
                },
                children: [
                  {
                    path: "",
                    name: "pip_capital_giro_list",
                    component: () =>
                      import("@/view/pages/pip/capital_giro/PipCapitalGiroList.vue"),
                    props: true,
                  },
                  {
                    path: "new",
                    name: "pip_capital_giro_new",
                    component: () =>
                      import("@/view/pages/pip/capital_giro/PipCapitalGiroForm.vue"),
                    props: true
                  }
                ]
              },
              {
                path: "fluxo_caixa",
                component: {
                  render: c => c("router-view"),
                },
                children: [
                  {
                    path: "",
                    name: "pip_fluxo_caixa_list",
                    component: () =>
                      import("@/view/pages/pip/fluxo_caixa/PipFluxoCaixaList.vue"),
                    props: true,
                  },
                  {
                    path: "new",
                    name: "pip_fluxo_caixa_new",
                    component: () =>
                      import("@/view/pages/pip/fluxo_caixa/PipFluxoCaixaTmaForm.vue"),
                    props: true
                  }
                ]
              }
            ]
          },
        ]
      }
    ]
  },
  {
    path: "/",
    redirect: { name: "login" },
    component: () => import("@/view/pages/auth/Auth"),
    children: [
      {
        name: "login",
        path: "login",
        component: () => import("@/view/pages/auth/partials/Login")
      },
      {
        name: "register",
        path: "register",
        component: () => import("@/view/pages/auth/partials/Register")
      },
      {
        name: "recover",
        path: "recover",
        component: () => import("@/view/pages/auth/partials/Recover")
      },
      {
        name: "register_confirm",
        path: "register/:hash",
        component: () => import("@/view/pages/auth/partials/RegisterConfirm"),
        props: true
      },
      {
        name: "recover_confirm",
        path: "recover/:hash",
        component: () => import("@/view/pages/auth/partials/RecoverConfirm"),
        props: true
      }
    ]
  },
  {
    path: "*",
    redirect: "/404"
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/view/pages/error/Error-1.vue")
  }
];

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
