import Swal from "sweetalert2";

const BaseNormal = Swal.mixin({
  heightAuto: false
});

const BaseToast = Swal.mixin({
  heightAuto: false,
  // toast: true,
  // position: 'top-end',
  showConfirmButton: false,
  timerProgressBar: true,
  timer: 3000,
  didOpen: toast => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});

export const show = async msg => {
  return BaseNormal.fire({
    title: msg.title.toUpperCase(),
    text: msg.description,
    icon: msg.type
  });
};

export const showToast = async msg => {
  return BaseToast.fire({
    title: msg.title.toUpperCase(),
    text: msg.description,
    icon: msg.type
  });
};

export const confirm = async (config = {}, configFeedback = {}) => {
  const configDefault = {
    title: "Confirmação".toUpperCase(),
    text: "Tem certeza que deseja prosseguir?",
    icon: "warning",
    heightAuto: false,
    showDenyButton: false,
    showCancelButton: true,
    confirmButtonText: "Sim",
    denyButtonText: "Não",
    cancelButtonText: "Cancelar"
  };
  config = {
    ...configDefault,
    ...config
  };
  const configFeedbackDefault = {
    showConfirmFeedback: true,
    showDenyFeedback: true,
    confirmFeedbackText: "Feito!",
    denyFeedbackText: "Ok! Nada feito."
  };
  configFeedback = {
    ...configFeedbackDefault,
    ...configFeedback
  };
  const sf = Swal.fire(config);
  if (configFeedback.showConfirmFeedback || configFeedback.showDenyFeedback) {
    return sf.then(result => {
      if (result.isConfirmed && configFeedback.showConfirmFeedback) {
        showToast({ title: configFeedback.confirmFeedbackText, description: "", type: "success" });
      } else if (!result.isConfirmed && configFeedback.showDenyFeedback) {
        showToast({ title: configFeedback.denyFeedbackText, description: "", type: "info" });
      }
      return result;
    });
  } else {
    return sf;
  }
};

export const confirmDelete = async () => {
  try {
    const result = await confirm({
      title: "Confirmação de exclusão".toUpperCase(),
      text: "Tem certeza que deseja prosseguir com a exclusão? Depois de apagado não poderá ser desfeito."
    }, {
      showConfirmFeedback: false,
      showDenyFeedback: false
    });

    if (result.isConfirmed) {
      return Promise.resolve(result);
    } else {
      return Promise.reject(result);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const confirmRetryRequest = async () => {
  try {
    const result = await confirm({
      title: "Erro ao tentar buscar dados".toUpperCase(),
      text: "",
      icon: "error",
      confirmButtonText: "Tentar novamente",
      showConfirmButton: true,
      showDenyButton: false,
      showCancelButton: true
    }, {
      showConfirmFeedback: false,
      showDenyFeedback: false
    });

    if (result.isConfirmed) {
      return Promise.resolve(result);
    } else {
      return Promise.reject(result);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const confirmReloadCountdown = async () => {
  try {
    const result = await confirm({
      title: "TEMPO DE SESSÃO".toUpperCase(),
      text: "O tempo da sua sessão está prestes a expirar. Para não perder o trabalho realizado no Sistema clique no botão \"OK\".",
      icon: "warning",
      confirmButtonText: "OK",
      showConfirmButton: true,
      showDenyButton: false,
      showCancelButton: false
    }, {
      showConfirmFeedback: false,
      showDenyFeedback: false
    });

    if (result.isConfirmed) {
      return Promise.resolve(result);
    } else {
      return Promise.reject(result);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export default { show, showToast, confirm, confirmDelete, confirmRetryRequest, confirmReloadCountdown };
