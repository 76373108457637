import Router from 'vue-router'
import MyRouter from './index'
import store from "@/core/store";
import { VERIFY_AUTH } from "@/core/store/auth.module";

export const init = () => {
  const originalPush = Router.prototype.push
  Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
      return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch((err) => {
      if (Router.isNavigationFailure(err)) {
        // resolve err
        return err
      }
      // rethrow error
      return Promise.reject(err)
    })
  }

  MyRouter.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // this route requires auth, check if logged in
      store.dispatch(VERIFY_AUTH)
        .then(next)
        .catch(err => {
          if (err && err.response && err.response.status === 403) {
            // Valid token, but it is not allowed for that session.
            next({
              name: "inicio"
            });
          } else {
            // Invalid token
            next({
              name: "login",
              query: { redirect: to.fullPath }
            });
          }
        });
    } else {
      // this route doesn't require auth
      if (store.getters.isAuthenticated) {
        // the user is already logged in
        next({ name: "inicio" });
      } else {
        next();
      }
    }
  });
  MyRouter.afterEach(() => {
    // Scroll page to top on every route change
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  });
}

export default { init };
