const ID_TOKEN_KEY = "id_token";
const ID_EXP_TOKEN = "id_exp_token";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getExpToken = () => {
  return window.localStorage.getItem(ID_EXP_TOKEN);
};

export const saveToken = (token, exp_token) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
  window.localStorage.setItem(ID_EXP_TOKEN, exp_token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_EXP_TOKEN);
};

export default { getToken, getExpToken, saveToken, destroyToken };
