import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { Service } from "axios-middleware";
import router from "@/core/router";
import store from "@/core/store";
import { PURGE_AUTH, UPDATE_AUTH } from "@/core/store/auth.module";
import JwtService from "@/core/services/jwt.service";
import MsgService from "@/core/services/msg.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = "https://plano-negocios-api.fida.ufv.br/api/";

    this.setMiddleware();
  },

  setMiddleware() {
    const service = new Service(axios);

    service.register({
      onResponse(resp) {
        //Save new token on sucessful requests
        if (resp && resp.data) {
          // É necessário dar 'parse' na resposta devido o axios
          //  ainda não ter feito isso neste momento

          try {
            const data = JSON.parse(resp.data);
            if (data.token && data.exp_token) {
              const { token, exp_token } = data;
              store.commit(UPDATE_AUTH, { token, exp_token });
            }
          } catch (e) {
            return resp;
          }
        }
        return resp;
      },
      onResponseError(err) {
        // Show all error messages
        if (err && err.response && err.response.data) {
          // É necessário dar 'parse' na resposta devido o axios
          //  ainda não ter feito isso neste momento
          const data = JSON.parse(err.response.data);
          if (data.message) {
            MsgService.show(data.message);
          }
        }

        // Redirecting case 401 or 403
        if (err && err.response && err.response.status === 401) {
          store.commit(PURGE_AUTH);
          router.push({
            name: "login",
            query: { redirect: router.fullPath }
          });
        } else if (err && err.response && err.response.status === 403) {
          router.push({ name: "inicio" });
        } else {
          throw err;
        }
      }
    });
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `${JwtService.getToken()}`;
  },

  /**
   * Remove the default HTTP request headers
   */
  removeHeader() {
    delete Vue.axios.defaults.headers.common.Authorization;
  },

  /**
   * Return methods for resource
   */
  getMethods(resource) {
    const Request = async config => {
      return Vue.axios.request({ ...config, url: resource });
    };
    const GetList = async (xdata = null) => {
      let params = xdata ? { xdata } : {};
      return Vue.axios.get(resource, { params }).then(({ data }) => {
        return Promise.resolve(data.response);
      });
    };
    const GetData = async (slug = null, xdata = null) => {
      let params = xdata ? { xdata } : {};
      return Vue.axios
        .get(`${resource}/${slug !== null ? slug : "new"}`, { params }).then(({ data }) => {
          return Promise.resolve(data.response);
        });
    };
    const GetFile = async (slug = null, xdata = null) => {
      let params = xdata ? { xdata } : {};
      return Vue.axios
        .get(`${resource}/${slug}`, { responseType: 'blob', params })
        .then((response) => {
          return Promise.resolve(response.data);
        });
    };
    const Add = async (data, xdata = null) => {
      let params = xdata ? { data, xdata } : { data };
      return Vue.axios
        .post(`${resource}`, params)
        .then((res) => {
          if (res.data.message) {
            MsgService.showToast(res.data.message);
          }
          if (res.data && res.data.response && res.data.response.data) {
            return Promise.resolve(res.data.response.data);
          } else {
            return Promise.resolve(res);
          }
        });
    };
    const Update = async (slug, data, xdata = null) => {
      let params = xdata ? { data, xdata } : { data };
      return Vue.axios
        .put(`${resource}/${slug ?? ''}`, params)
        .then((response) => {
          if (response.data.message) {
            MsgService.showToast(response.data.message);
          }
          return Promise.resolve(response);
        });
    };
    const Remove = async (ids, xdata = null) => {
      try {
        const result = await MsgService.confirmDelete();

        if (result.isConfirmed) {
          let data = xdata ? { ids, xdata } : { ids };
          return Vue.axios.delete(resource, { data })
            .then((response) => {
              if (response.data.message) {
                MsgService.showToast(response.data.message);
              }
              return Promise.resolve(response);
            });
        } else {
          return Promise.reject(result);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    };
    const RemoveItem = async (id, xdata = null) => {
      return await Remove([id], xdata);
    };

    return { Request, GetList, GetData, GetFile, Add, Update, Remove, RemoveItem };
  },

  query(resource, params) {
    return Vue.axios.get(resource, params);
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "", options = {}) {
    return Vue.axios.get(`${resource}/${slug}`, options);
  },

  /**
   * Send the GET blob HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  getBlob(resource, slug = "", options = {}) {
    return Vue.axios.get(`${resource}/${slug}`, { responseType: 'blob', ...options });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @param data
   * @returns {*}
   */
  delete(resource, data) {
    const params = data ? (data) : {};
    return Vue.axios.delete(resource, params);
  }
};

export default ApiService;
