import ApiService from "@/core/services/api.service";

// action types
export const INIT_MANUAL = "initManual";

// mutation types
export const RESET_MANUAL = "resetManual";
export const SET_MANUAL = "setManual";

export default {
  state: {
    manual: null,
    dicas: []
  },
  getters: {
    /**
     * Get the manual
     * @param state
     * @returns {*}
     */
    manual(state) {
      return state.manual;
    },

    /**
     * Get the list of dicas
     * @param state
     * @returns {*}
     */
    dicas(state) {
      return state.dicas;
    }
  },
  actions: {
    /**
     * Get and set the manual
     * @param context
     * @param payload
     */
    [INIT_MANUAL](context, payload) {
      ApiService.get(`manual/${payload.session}/${payload.tipo}`)
        .then(({ data }) => {
          context.commit(SET_MANUAL, data.response);
          Promise.resolve();
        })
        .catch(error => Promise.reject(error));
    }
  },
  mutations: {
    [RESET_MANUAL](state) {
      state.manual = null;
      state.dicas = [];
    },
    [SET_MANUAL](state, payload) {
      state.manual = payload.manual ?? null;
      state.dicas = payload.dicas ?? [];
    }
  }
};
