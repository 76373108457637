import Vue from "vue";
import App from "./App.vue";

import router from "@/core/router";
import routerMiddlewares from "@/core/router/middlewares";
import store from "@/core/store";
import ApiService from "@/core/services/api.service";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@/core/plugins/vue2-dropzone";
import "@mdi/font/css/materialdesignicons.css";

// API service init
ApiService.init();

// Router middlewares init
routerMiddlewares.init();

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
